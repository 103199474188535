import React from "react"
import Template from "../../templates/case-studies"
import { useStaticQuery, graphql } from "gatsby"
import { CASE_STUDIES } from "../../config"

const Page = props => {
  return (
    <Template
      {...props}
      options={CASE_STUDIES["2-dag-hammerskjold-plaza"]}
    ></Template>
  )
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "2dag/lobby_address_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "2dag/outside_cool_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "2dag/outside_trees_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      banner: file(relativePath: { eq: "banners/2-dag.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const imageOne = data.imageOne.childImageSharp.fluid
  const imageTwo = data.imageTwo.childImageSharp.fluid
  const imageThree = data.imageThree.childImageSharp.fluid
  const banner = data.banner.childImageSharp.fluid
  const images = [imageOne, imageTwo, imageThree, banner]
  return <Page {...props} images={images} />
}
